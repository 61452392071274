import React from 'react';
import logo from './logo.svg';
import banner from './doomergirl-banner.jpeg';
import merchBanner from './merch-banner.jpg';
import './App.css';

import { FaXTwitter } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa6";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/*<img src={logo} className="App-logo" alt="logo" />*/}
        <img src={banner} className="App-banner" alt="banner" />
        <p>
          DOOMERGIRL WORLD DOMINATION
        </p>
        <div className="social-links">
          <a
            className="App-link"
            href="https://x.com/doomergirl_eth"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaXTwitter />
          </a>
          <a
            className="App-link"
            href="https://t.me/DMRGRL"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegram />
          </a>
        </div>
        {/*
        <p>
          Merch
        </p>
        <img src={merchBanner} className="merch-banner" alt="banner" />
        */}
      </header>
    </div>
  );
}

export default App;
